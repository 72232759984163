#__next {
  height: 100%;
}

.timeSlider {
  width: calc(100% + 1rem);
  position: relative;
}

.timeSlider .slick-track {
  display: flex;
  gap: 10px;
}

.timeSlider .slick-list {
  margin: 0;
  overflow: visible;
}

.timeSlider .slick-arrow.slick-disabled path {
  opacity: 0.2;
}

@media screen and (min-width: 600px) {
  .timeSlider {
    width: 100%;
  }

  .timeSlider .slick-list {
    margin: 0 20px;
    overflow: hidden;
  }

  .timeSlider .slick-arrow path {
    stroke: #000;
  }
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: '#EEF1FF'
}

div.ant-page-header {
  background: none;
}

#flexInputs .ant-row.ant-form-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

#react-app {
  min-height: 100vh;
  height: 100%;
}

hr {
  border: 0.5px rgba(0, 0, 0, 0.2) solid;
}

.sidebar-businessMenu ul.ant-menu-inline {
  background: none;
  color: white;
}

body {
  font-size: 16px;
  background-image: linear-gradient(180deg, #f9fbfd 0, #fff);
  background-repeat: no-repeat;
  height: 100%;
  background-color: unset !important;
  overflow-x: hidden;
}

html {
  height: 100%;
}

.firebase-emulator-warning {
  display: none;
}

.ant-modal-title {
  color: '#000000';
  font-weight: 400;
  font-family: gelica-light, serif;
  font-size: 1.375rem;
  line-height: '1.2';
  letter-spacing: 0em;
  display: block;
}
