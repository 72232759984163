@font-face {
  font-family: Lark;
  src: url('./assets/Larke\ Neue\ Regular.ttf');
}

@font-face {
  font-family: 'gelica-light';
  src: url('./assets/fonts/gelica-light-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}