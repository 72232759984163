#firebaseui-container *{
  font-family: "proxima-nova", sans-serif !important;
}

#firebaseui-container {
  width: 100%;
}

#firebaseui-container .firebaseui-container,
.firebaseui-card-content,
.firebaseui-card-header,
.firebaseui-card-actions{
  max-width: 100%;
  box-shadow: none;
  padding: 0;
}

#firebaseui-container .mdl-textfield{
  padding: 0;
}

#firebaseui-container button[data-provider-id="password"] {
  padding: 10px 25px;
  margin: 0 auto;
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  background: #F47451;
  border-radius: 9999px !important;
  max-width: 390px;
  color: #000000 !important;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  @media screen and (min-width: 600px){
    font-size: 14px;
    line-height: 17px;
  }
}

#firebaseui-container button:focus, #firebaseui-container button:hover {
  background: #FFA288;
}

#firebaseui-container .firebaseui-input-floating-button{
  top: 0;
}

#firebaseui-container .firebaseui-form-actions{
  display: flex;
  button.firebaseui-id-secondary-link{
    margin-left: 0;
    width: auto;
    background: transparent;
    color: #5176A9 !important;
  }
}

#firebaseui-container input {
  border: 1px solid #D9D9D9;
  color: black;
  background-color: white;
  padding: 11px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.33;
}

#firebaseui-container button[data-provider-id="password"] .firebaseui-idp-text{
  color: black;
  padding: 0
}

#firebaseui-container button[data-provider-id="password"] .firebaseui-idp-icon-wrapper {
    display: none;
}

#firebaseui-container button.firebaseui-idp-password {
  background-color: #F47451 !important;
  box-shadow: none;
}

#firebaseui-container button.firebaseui-idp-password:hover {
  background-color: #F47451 !important;
}

#firebaseui-container .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-sign-in {
  box-shadow: none;
  justify-content: center;
}

#firebaseui-container label.mdl-textfield__label.firebaseui-label {
  position: initial;
  margin-bottom: .25rem;
  font-size: 14px;
}

#firebaseui-container label.firebaseui-label.mdl-textfield__label:after {
  display: none;
}

#firebaseui-container .firebaseui-title {
  display: none;
}

#firebaseui-container button.firebaseui-id-secondary-link.firebaseui-button.mdl-button.mdl-js-button.mdl-button--primary {
  color: #5176A9;
}

#firebaseui-container button.firebaseui-id-submit.firebaseui-button.mdl-button.mdl-js-button.mdl-button--raised.mdl-button--colored {
  color: white;
  border: none;
  font-size: .85rem;
  font-weight: 600;
  line-height: 1.6;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border-radius: .375rem;
  background-color: #F47451 !important;
  box-shadow: none;
}

#firebaseui-container button.firebaseui-id-submit.firebaseui-button.mdl-button.mdl-js-button.mdl-button--raised.mdl-button--colored:hover {
  background-color: #FFA288 !important
}

#firebaseui-container .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-in {
  box-shadow: none;
}

#firebaseui-container a.firebaseui-link.firebaseui-id-secondary-link {
  color: #5176A9;
}

#firebaseui-container .firebaseui-text-input-error {
  margin: 0;
}

